import React, { useCallback, useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { omit, noop } from 'lodash';
import { Container } from 'reactstrap';
// Utils
import { srpLinkBuilderWithParamsConversion } from 'site-modules/shared/utils/srp-link-constructor';
import { EventToolbox } from 'client/utils/event-toolbox';
// Constants
import {
  ANY_BTN,
  ONLINE_BTN,
  IN_STORE_BTN,
  BUYING_EXPERIENCE_CREATIVE_ID,
  ANY_BTN_LABEL,
  ONLINE_BTN_LABEL,
  IN_STORE_BTN_LABEL,
} from 'client/site-modules/shared/constants/inventory/buying-experience';
import { TrackingConstant } from 'client/tracking/constant';
import { DELIVERY_TYPE } from 'client/site-modules/shared/constants/allowed-inventory-request-params';
// Components
import { Link } from 'site-modules/shared/components/link/link';
import { DeliveryToggle } from 'site-modules/shared/components/delivery-toggle/delivery-toggle';
import { ToggleButtonGroup } from 'site-modules/shared/components/inventory/toggle-button-group/toggle-button-group';
import { ToggleButton } from 'site-modules/shared/components/inventory/toggle-button/toggle-button';

function getLink(currentLink, facets) {
  const links = {
    [ANY_BTN]: srpLinkBuilderWithParamsConversion(omit(facets, DELIVERY_TYPE)),
    [IN_STORE_BTN]: srpLinkBuilderWithParamsConversion({
      ...facets,
      [DELIVERY_TYPE]: 'local',
    }),
    [ONLINE_BTN]: srpLinkBuilderWithParamsConversion(omit(facets, DELIVERY_TYPE), true),
  };

  return links[currentLink];
}

export const EvEBuyingExperienceButtonGroup = memo(
  ({
    selectedFacets,
    isMobile,
    selectedBtn,
    showDeliveryFreeToggle,
    toggleDeliveryFees,
    includeDeliveryFee,
    withCoreReviewSection,
    headingTag: HeadingTag,
  }) => {
    const [selectedButton, setSelectedButton] = useState(selectedBtn);

    useEffect(() => {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
        event_data: {
          action_name: TrackingConstant.ACTION_WIDGET_VIEW,
          action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
          creative_id: BUYING_EXPERIENCE_CREATIVE_ID,
          value: selectedButton,
        },
      });
    }, [selectedButton]);

    const handleChange = useCallback((event, value) => {
      if (!value) return;
      setSelectedButton(value);
    }, []);

    const buttonsSrpCommonProps = {
      tag: Link,
      rel: 'nofollow',
      'data-tracking-parent': BUYING_EXPERIENCE_CREATIVE_ID,
      'data-tracking-id': 'inventory_view_filter_search_result',
      style: {
        borderRadius: 'inherit',
        padding: '0.375rem 0.75rem',
        flex: '1 1 0',
      },
    };

    const buttonsSrp = (
      <ToggleButtonGroup
        value={selectedButton}
        onChange={handleChange}
        style={{
          display: 'flex',
          padding: '2px',
          borderRadius: '5rem',
          width: '100%',
        }}
      >
        <ToggleButton
          {...buttonsSrpCommonProps}
          to={getLink(ANY_BTN, selectedFacets)}
          value={ANY_BTN}
          data-tracking-value={ANY_BTN}
          aria-label={ANY_BTN_LABEL}
        />
        <ToggleButton
          {...buttonsSrpCommonProps}
          to={getLink(ONLINE_BTN, selectedFacets)}
          value={ONLINE_BTN}
          data-tracking-id="view_search_result_online"
          data-tracking-value={ONLINE_BTN}
          aria-label={ONLINE_BTN_LABEL}
        />
        <ToggleButton
          {...buttonsSrpCommonProps}
          to={getLink(IN_STORE_BTN, selectedFacets)}
          value={IN_STORE_BTN}
          data-tracking-value={IN_STORE_BTN}
          aria-label={IN_STORE_BTN_LABEL}
        />
      </ToggleButtonGroup>
    );

    return (
      <Container style={{ paddingRight: 0, paddingLeft: 0 }}>
        <fieldset
          style={{
            ...(isMobile
              ? { padding: `0 4px ${withCoreReviewSection ? 24 : 20}px`, border: 0 }
              : { padding: '0 16px 20px', borderBottom: '0.5px solid #cbd4e1' }),
          }}
        >
          <legend
            className={classNames('mb-1', {
              'visually-hidden': withCoreReviewSection && isMobile,
            })}
            style={isMobile ? { textAlign: 'center' } : { paddingTop: '16px' }}
          >
            <HeadingTag className="heading-5" style={{ marginBottom: 0, ...(isMobile ? { fontWeight: '400' } : {}) }}>
              {isMobile ? 'Select Your Buying Experience' : 'Select Buying Experience'}
            </HeadingTag>
          </legend>
          {buttonsSrp}
          {showDeliveryFreeToggle && (
            <DeliveryToggle
              isMobile={isMobile}
              toggleDeliveryFees={toggleDeliveryFees}
              includeDeliveryFee={includeDeliveryFee}
            />
          )}
        </fieldset>
      </Container>
    );
  }
);

EvEBuyingExperienceButtonGroup.displayName = 'EvEBuyingExperienceButtonGroup';

EvEBuyingExperienceButtonGroup.propTypes = {
  selectedFacets: PropTypes.shape({}),
  isMobile: PropTypes.bool,
  selectedBtn: PropTypes.string,
  showDeliveryFreeToggle: PropTypes.bool,
  toggleDeliveryFees: PropTypes.func,
  includeDeliveryFee: PropTypes.bool,
  withCoreReviewSection: PropTypes.bool,
  headingTag: PropTypes.string,
};

EvEBuyingExperienceButtonGroup.defaultProps = {
  selectedFacets: {},
  isMobile: false,
  selectedBtn: ANY_BTN,
  showDeliveryFreeToggle: false,
  toggleDeliveryFees: noop,
  includeDeliveryFee: false,
  withCoreReviewSection: false,
  headingTag: 'h2',
};
