import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { InventoryEntities } from 'client/data/models/inventory';
import { getPlural } from 'client/utils/plural';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { transformSelectedFacetsForDisplaying } from 'site-modules/shared/utils/inventory/search-filter';

import { buildFacetValueNiceName } from 'site-modules/shared/utils/inventory/build-facet-value-nice-name';

import './usurp-filtering-by-collapse.scss';

export function UsurpFilteringByCollapse({
  facets,
  onUpdate,
  className,
  suggestedFacetsComponent: SuggestedFacetsComponent,
}) {
  const [isContentCollapsed, setIsContentCollapsed] = useState(false);

  if (!facets.length) {
    return null;
  }

  const filters = transformSelectedFacetsForDisplaying(facets).map(facet => buildFacetValueNiceName(facet));
  const btnFilterText = `${filters.length} Applied ${getPlural('Filter', filters.length)}`;

  return (
    filters.length > 0 && (
      <div className={classnames('usurp-filtering-by', className)}>
        <div className="d-flex pos-r">
          <ContentCollapse
            textClosed={btnFilterText}
            textOpen={btnFilterText}
            justify="justify-content-start"
            btnContainerClasses="m-0 p-0"
            btnClasses="usurp-filtering-by-collapse-btn size-16 text-primary-darker p-0 fw-normal text-lowercase"
            isEclipseFade={false}
            onToggleContentCollapseState={setIsContentCollapsed}
            isOpenOnLoad
          >
            <div className="d-flex flex-wrap mt-1">
              <SuggestedFacetsComponent
                suggestedFacets={filters}
                onSuggestedFacetClick={onUpdate}
                tabIndex={isContentCollapsed ? -1 : 0}
                wrapperClassnames="flex-wrap"
                itemClassnames="mb-0_5 active-filters-item"
                shouldWrap={false}
                withInStorePurchaseButton={false}
              />
            </div>
          </ContentCollapse>
          <button
            onClick={onUpdate}
            type="button"
            className="clear-all-btn text-gray-darker size-16 pr-0 m-0 pos-a right-0 d-flex align-items-center"
          >
            <i className="icon icon-cross2 size-12 mr-0_25" aria-hidden />
            <span>Clear all</span>
            <span className="visually-hidden"> active filters</span>
          </button>
        </div>
      </div>
    )
  );
}

UsurpFilteringByCollapse.propTypes = {
  facets: InventoryEntities.Facets,
  onUpdate: PropTypes.func.isRequired,
  className: PropTypes.string,
  suggestedFacetsComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

UsurpFilteringByCollapse.defaultProps = {
  facets: [],
  className: null,
};
