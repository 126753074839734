import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';

export function FiltersGroupTitle({
  isSrOnlyTitle,
  title,
  baseTitleClassNames,
  baseTooltipClassNames,
  tooltip,
  legendConfig,
  wrapperTag: WrapperTag,
  titleTag: TitleTag,
}) {
  const { legendClassNames, titleClassNames } = legendConfig;
  const tooltipId = tooltip?.id || '';
  const iconClassName = tooltip?.iconClassName || '';
  const tooltipText = tooltip?.text || '';
  const hasTooltip = !!tooltipText;

  return (
    <WrapperTag className={classnames(legendClassNames, { 'visually-hidden': isSrOnlyTitle })}>
      <TitleTag className={classnames(baseTitleClassNames, titleClassNames)}>{title}</TitleTag>
      {hasTooltip && (
        <TooltipItem
          id={tooltipId}
          className={classnames('top-0 size-10', baseTooltipClassNames)}
          iconClassName={iconClassName}
          screenReaderTooltipLabel={title}
          item={{
            text: tooltipText,
            placement: 'auto',
          }}
        />
      )}
    </WrapperTag>
  );
}

FiltersGroupTitle.propTypes = {
  wrapperTag: PropTypes.string,
  titleTag: PropTypes.string,
  baseTitleClassNames: PropTypes.string,
  baseTooltipClassNames: PropTypes.string,
  isSrOnlyTitle: PropTypes.bool,
  title: PropTypes.string,
  tooltip: PropTypes.shape({ id: PropTypes.string, text: PropTypes.string, iconClassName: PropTypes.string }),
  legendConfig: PropTypes.shape({
    legendClassNames: PropTypes.string,
    titleClassNames: PropTypes.string,
  }),
};
FiltersGroupTitle.defaultProps = {
  wrapperTag: 'legend',
  titleTag: 'h3',
  baseTitleClassNames: undefined,
  baseTooltipClassNames: undefined,
  isSrOnlyTitle: false,
  title: '',
  tooltip: {},
  legendConfig: {
    legendClassNames: '',
    titleClassNames: null,
  },
};
