import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash';
/* Constants */
import { CAPITAL_ONE_CREDIT_PROVIDER } from 'site-modules/shared/constants/inventory/static-facets';
import { CREDIT_PROVIDER } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { FILTER_SWITCHER_INFO, GOT_IT } from 'client/site-modules/shared/constants/financing/texts';

import './usurp-switch-filter.scss';

export function UsurpSwitchFilterUI({ copy, onUpdate, selectedFacets, isMobile }) {
  const pressed = get(selectedFacets, `${CREDIT_PROVIDER}[0]`, '') === CAPITAL_ONE_CREDIT_PROVIDER;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [outerTopPosition, setOuterTopPosition] = useState(null);
  const outerRef = useRef(null);
  const toggle = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  useEffect(() => {
    if (isMobile && isPopoverOpen && outerRef.current) {
      setOuterTopPosition(Math.abs(Number(outerRef.current.getBoundingClientRect().top)));
    }
    // eslint-disable-next-line
  }, [isPopoverOpen]);

  const popoverBody = (
    <div className={classNames('pos-a finance-tooltip fade py-0_75', { show: isPopoverOpen })}>
      <div
        className="w-100 finance-tooltip-outer"
        onClick={toggle}
        aria-hidden
        ref={outerRef}
        style={{ height: outerTopPosition ? `calc(${outerTopPosition}px + 100vh)` : '100vh' }}
      />
      <div className="finance-tooltip-inner p-1 text-start bg-white">
        <p>{FILTER_SWITCHER_INFO}</p>
        <Button
          color="link"
          className="p-0 text-primary-darker text-transform-none fw-normal c-pointer size-16 text-underline"
          data-testid="close-info"
          onClick={toggle}
        >
          {GOT_IT}
        </Button>
      </div>
      <span className="arrow pos-a d-block" />
    </div>
  );
  const handleClick = () => {
    onUpdate({ name: CREDIT_PROVIDER, value: CAPITAL_ONE_CREDIT_PROVIDER, type: CREDIT_PROVIDER }, !pressed);
  };

  return (
    <Fragment>
      <div
        className={classNames('financing-toggle-wrapper d-flex pos-r', {
          checked: pressed,
        })}
      >
        <div className="d-flex">
          <button
            role="switch"
            aria-checked={pressed}
            aria-labelledby="financingToggle"
            className="slider round pos-r mt-0_25"
            style={{
              cursor: 'pointer',
              width: '46px',
              minWidth: '46px',
              height: '16px',
              backgroundColor: '#fff',
              border: '1px solid #ccc',
              borderRadius: '100px',
              ...(pressed && { backgroundColor: '#0069bf' }),
            }}
            onClick={handleClick}
            data-testid="toggle-btn"
            data-tracking-id="toggle_financing"
            data-tracking-value={`Prequalification~${!pressed}~${copy}`}
          />
          <div id="financingToggle" className="name ml-0_5">
            <span className="mr-0_5">{copy}</span>
            <div className="d-inline-block align-text-bottom">
              <Button
                onClick={toggle}
                color="link"
                className="p-0 d-inline-block"
                role="tooltip"
                data-testid="tooltip-btn"
              >
                <i className="icon icon-info size-12 text-gray-darker" aria-hidden />
              </Button>
              {isPopoverOpen && popoverBody}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = state => ({
  isMobile: get(state, 'mobile'),
});

UsurpSwitchFilterUI.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  copy: PropTypes.string,
  selectedFacets: PropTypes.shape({}),
  isMobile: PropTypes.bool,
};

UsurpSwitchFilterUI.defaultProps = {
  copy: '',
  selectedFacets: {},
  isMobile: false,
};

export const UsurpSwitchFilter = connect(mapStateToProps)(UsurpSwitchFilterUI);
