import React from 'react';
import PropTypes from 'prop-types';
import { FacetsConfigShape } from 'site-modules/shared/constants/inventory/filters-constants';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';

export function UsurpFilterLegend({ config, withTitle, isElectricRangeFacetDisplayed }) {
  const { tooltip, title, alternativeTitle } = config;
  const hasTitle = Boolean(withTitle && title);
  const tooltipId = tooltip?.id || '';
  const iconClassName = tooltip?.iconClassName || '';
  const tooltipText = tooltip?.text || '';
  const displayedTitle = isElectricRangeFacetDisplayed && alternativeTitle ? alternativeTitle : title;

  const hasTooltip = !!tooltipText;

  if (!hasTitle) return null;

  return (
    <legend className="mb-0_5">
      <h4 className="text-gray-darker mb-0 fw-medium d-inline-block size-14">{displayedTitle}</h4>
      {hasTooltip && (
        <TooltipItem
          id={tooltipId}
          className="top-0 size-10"
          iconClassName={iconClassName}
          screenReaderTooltipLabel={displayedTitle}
          item={{
            text: tooltipText,
            placement: 'auto',
          }}
        />
      )}
    </legend>
  );
}

UsurpFilterLegend.propTypes = {
  withTitle: PropTypes.bool.isRequired,
  isElectricRangeFacetDisplayed: PropTypes.bool,
  config: FacetsConfigShape.isRequired,
};
UsurpFilterLegend.defaultProps = {
  isElectricRangeFacetDisplayed: false,
};
