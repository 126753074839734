import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { debounce, get, delay, noop, inRange, isFinite } from 'lodash';
import { numberWithCommas } from 'site-modules/shared/utils/string';
import { ENTER_KEY_CODE, SPACE_KEY_CODE, ESC_KEY_CODE } from 'client/site-modules/shared/constants/key-codes';
import { RangeInputsPanel } from 'site-modules/shared/components/range/range-inputs-panel/range-inputs-panel';

import './range.scss';

function getTopOffset(el) {
  try {
    return el.getBoundingClientRect().top;
  } catch (e) {
    return 0;
  }
}

const getFormattedInputValue = ({ formatInputValue, value, fractionDigits }) =>
  formatInputValue && isFinite(value) ? numberWithCommas(value, fractionDigits, fractionDigits) : value;

export const VALUE_INPUTS_NAMES = {
  MIN: 'min-value-input',
  MAX: 'max-value-input',
};

export const LABELS_PANEL_PLACEMENTS = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

// CSS solution for a two-thumbs slider is based on the following article
// https://css-tricks.com/multi-thumb-sliders-particular-two-thumb-case/

export class Range extends Component {
  state = {
    isMinSliderActive: false,
    isMaxSliderActive: false,
    initialMin: this.props.selectedMin,
    initialMax: this.props.selectedMax,
    holdNextChange: false,
    minInputValue: this.props.selectedMin,
    maxInputValue: this.props.selectedMax,
    isInputChangeInProgress: false,
  };

  static getDerivedStateFromProps(props, state) {
    const { selectedMin, selectedMax } = props;
    const { isInputChangeInProgress, minInputValue, maxInputValue } = state;

    // Do not override inputs values from props when they are in process of change
    // If values from props and in the state are the same, it means that change process is ended
    if (isInputChangeInProgress) {
      return minInputValue === selectedMin && maxInputValue === selectedMax ? { isInputChangeInProgress: false } : null;
    }

    return { minInputValue: selectedMin, maxInputValue: selectedMax };
  }

  onSliderPointerMove = e => {
    const { selectedMin } = this.props;
    const { isMaxSliderActive } = this.state;

    if (isMaxSliderActive) {
      const pointVal = this.getValueFromEventPosition(e);

      // Change event is not called on the top (with "top-slider" class) slider when
      // we move the opposite slider to the same value that the top slider currently has
      // So this is a little hack to trigger change callback on the opposite slider
      // When cursor/touch moves its thumb to the equal value
      if (pointVal === selectedMin) {
        this.props.onMaxChange(pointVal);
      }
    }
  };

  onPointerMove = e => {
    e.persist();

    this.debouncedOnSliderPointerMove(e);
  };

  onMouseDown = e => {
    this.activateSlidersByCursorPosition(e);
  };

  onMouseUp = () => {
    const { handleUpdate } = this.props;

    this.resetActiveSlidersFlags();
    handleUpdate();
  };

  onTouchStart = e => {
    this.activateSlidersByCursorPosition(e);
    this.scrollPosition = getTopOffset(this.minSliderRef.current);
    this.setState({ holdNextChange: true });
  };

  onTouchEnd = () => {
    const { handleUpdate, selectedMin, selectedMax } = this.props;

    this.resetActiveSlidersFlags();

    if (this.getScrollPositionChanged()) {
      this.resetValuesToInitial();
    } else {
      this.setState(
        {
          initialMin: selectedMin,
          initialMax: selectedMax,
        },
        () => {
          handleUpdate();
        }
      );
    }
  };

  onInputBlur = e => {
    const {
      min,
      max,
      selectedMin,
      selectedMax,
      onMinChange,
      onMaxChange,
      handleUpdate,
      allowEqualValues,
      step,
    } = this.props;
    const { minInputValue, maxInputValue } = this.state;

    // inRange checks if value is between start and up to, but not including, end.
    const rangeMax = max + 1;

    const isMinValueValid = isFinite(minInputValue);
    const isMaxValueValid = !(maxInputValue === 0 && maxInputValue === selectedMin) && isFinite(maxInputValue);

    const isMinInput = e.target.name === VALUE_INPUTS_NAMES.MIN;

    const adjustedMinValue = minInputValue < min ? min : max;
    const adjustedMaxValue = maxInputValue < min ? min : max;
    let newMinValue = inRange(minInputValue, min, rangeMax) ? minInputValue : adjustedMinValue;
    if (!allowEqualValues && isMinInput && newMinValue === maxInputValue) {
      newMinValue -= step;
    }

    let newMaxValue = inRange(maxInputValue, min, rangeMax) ? maxInputValue : adjustedMaxValue;
    if (!allowEqualValues && !isMinInput && newMaxValue === minInputValue) {
      newMaxValue += step;
    }
    const valuesChanged = newMinValue !== selectedMin || newMaxValue !== selectedMax;

    if (isMinValueValid && isMaxValueValid && valuesChanged) {
      if (isMinInput) {
        onMinChange(newMinValue, true);
      } else {
        onMaxChange(newMaxValue, true);
      }
      return;
    }

    if (!isMinValueValid || !valuesChanged) {
      this.setState({ minInputValue: selectedMin });
    }

    if (!isMaxValueValid || !valuesChanged) {
      this.setState({ maxInputValue: selectedMax });
    }

    handleUpdate();
  };

  onInputKeyDown = e => {
    e.persist();

    if (e.keyCode === ENTER_KEY_CODE || e.keyCode === SPACE_KEY_CODE) {
      e.preventDefault();
      this.onInputBlur(e);
    }

    if (e.keyCode === ESC_KEY_CODE) {
      this.setState(
        {
          minInputValue: this.props.selectedMin,
          maxInputValue: this.props.selectedMax,
          isInputChangeInProgress: false,
        },
        () => {
          e.target.blur();
        }
      );
    }
  };

  onInputFocus = event => {
    event.target.select();
  };

  getValueFromEventPosition = e => {
    const { min, max } = this.props;

    const diff = max - min;
    const minSliderEl = this.minSliderRef.current;

    const { left, width } = minSliderEl.getBoundingClientRect();

    const xPosition = e.clientX || get(e, ['touches', 0, 'clientX']);

    const point = (xPosition - left) / width;
    return Math.round(diff * point) + min;
  };

  // Checks if scroll position has changed since the beginning of touch events
  getScrollPositionChanged = () => this.scrollPosition !== getTopOffset(this.minSliderRef.current);

  resetValuesToInitial = () => {
    const { onMinChange, onMaxChange, hasTwoThumbs } = this.props;
    const { initialMin, initialMax } = this.state;

    onMinChange(initialMin);

    if (hasTwoThumbs) {
      onMaxChange(initialMax);
    }
  };

  scrollPosition = null;

  debouncedOnSliderPointerMove = debounce(this.onSliderPointerMove, 100);

  resetActiveSlidersFlags = () => {
    this.setState({ isMaxSliderActive: false, isMinSliderActive: false, holdNextChange: false });
  };

  handleChangeMin = e => {
    this.handleChange(e, this.activateMinSlider);
  };

  handleChangeMax = e => {
    this.handleChange(e, this.activateMaxSlider);
  };

  handleChangeForTwoThumbsSlider = e => {
    this.handleChange(e, this.activateSliders);
  };

  handleChange = (e, cb) => {
    const { holdNextChange } = this.state;
    const value = +e.target.value;

    e.preventDefault();

    if (this.delayTimerId) {
      clearTimeout(this.delayTimerId);
      this.delayTimerId = null;
    }

    if (holdNextChange) {
      this.delayActivation(value, cb);
    } else {
      cb(value);
    }
  };

  handleInputChange = e => {
    const { formatInputValue } = this.props;
    const { minInputValue, maxInputValue } = this.state;

    e.persist();

    const { value: targetValue, name, selectionStart } = e.target;

    // E.g. 0,000 when users want to change 10,000 to 20,000 by removing the first symbol
    // "0" input is not included in this case as it is a valid number
    const hasOnlyZeros = /0{2,}/.test(targetValue) && /^(,*0)+$/.test(targetValue);

    // Clean value (E.g. from -80,000.000 to 80000000)
    const inputValue = targetValue && !hasOnlyZeros ? +targetValue.replace(/,|\.|-/g, '') : targetValue;
    const value = isFinite(inputValue) || hasOnlyZeros ? inputValue : '';

    const isMinInput = name === VALUE_INPUTS_NAMES.MIN;

    this.setState(
      {
        ...(isMinInput ? { minInputValue: value } : { maxInputValue: value }),
        isInputChangeInProgress: true,
      },
      () => {
        // Adjust caret position
        const formattedNewValue = getFormattedInputValue({ formatInputValue, value });
        const prevValue = isMinInput ? minInputValue : maxInputValue;
        const formattedPrevValue = getFormattedInputValue({ formatInputValue, value: prevValue });

        const valuesLenDiff = formattedNewValue.length - formattedPrevValue.length;
        let caretPositionOffset = 0;

        if (valuesLenDiff === -2) {
          // One symbol and comma were removed
          caretPositionOffset = -1;
        } else if (valuesLenDiff === 2) {
          // One symbol and comma were addded
          caretPositionOffset = 1;
        }

        e.target.selectionStart = selectionStart + caretPositionOffset;
        e.target.selectionEnd = selectionStart + caretPositionOffset;
      }
    );
  };

  activateSlidersByCursorPosition = e => {
    const { selectedMin, selectedMax, min, max } = this.props;
    const { isMinSliderActive, isMaxSliderActive } = this.state;

    const isCloseValues = max - min === 1;
    const isAnySliderActive = isMinSliderActive || isMaxSliderActive;

    // In a situation with close values (e.g. 2020 and 2021 years slider) the logic in activateSliders method
    // would noy work as expected as selecting the other value always activates the opposite slider because
    // of the value that the change event passes
    // This function activates the slider closest to the cursor position
    if (isCloseValues && !isAnySliderActive) {
      const value = this.getValueFromEventPosition(e);

      if (value === selectedMin) {
        this.activateMinSlider(value);
      } else if (value === selectedMax) {
        this.activateMaxSlider(value);
      }
    }
  };

  // Pass change event to the slider closest to clicked point on the track
  activateSliders = value => {
    const { selectedMin, selectedMax } = this.props;
    const { isMinSliderActive, isMaxSliderActive } = this.state;

    const isAnySliderActive = isMinSliderActive || isMaxSliderActive;

    const dMin = Math.abs(selectedMin - value);
    const dMax = Math.abs(selectedMax - value);

    const activateMinSlider = !isAnySliderActive ? dMin <= dMax : isMinSliderActive;

    if (activateMinSlider) {
      this.activateMinSlider(value);
    } else {
      this.activateMaxSlider(value);
    }
  };

  activateMaxSlider = value => {
    const { selectedMin, allowEqualValues, step } = this.props;
    const shouldOverrideEqualValue = !allowEqualValues && value === selectedMin;
    this.setState({ isMinSliderActive: false, isMaxSliderActive: true });
    this.props.onMaxChange(shouldOverrideEqualValue ? value + step : value);
  };

  activateMinSlider = value => {
    const { selectedMax, allowEqualValues, step } = this.props;
    const shouldOverrideEqualValue = !allowEqualValues && value === selectedMax;
    this.setState({ isMinSliderActive: true, isMaxSliderActive: false });
    this.props.onMinChange(shouldOverrideEqualValue ? value - step : value);
  };

  // Prevent immediate call of onChange callbacks so that range values
  // are not updated if user taps the range input while scrolling on the touch screens
  delayActivation = (value, cb) => {
    this.setState({ holdNextChange: false });

    this.delayTimerId = delay(
      holdValue => {
        if (!this.getScrollPositionChanged()) {
          cb(holdValue);
        }
      },
      100,
      value
    );
  };

  minSliderRef = React.createRef();
  maxSliderRef = React.createRef();

  renderInputsPanel() {
    const {
      renderInputsPanel,
      hasTwoThumbs,
      showLabels,
      labelAddon,
      inputAddon,
      max,
      min,
      disabled,
      minOutputText,
      name,
      formatInputValue,
      step,
      fractionDigits,
    } = this.props;
    const { minInputValue, maxInputValue } = this.state;

    const hasEqualMinMax = min === max;
    const disableInputs = disabled || hasEqualMinMax;

    let inputSize = 'sm';

    if (max >= 100000) {
      inputSize = 'lg';
    } else if (inputAddon || max >= 10000) {
      inputSize = 'md';
    }

    const panelProps = {
      minValue: getFormattedInputValue({ formatInputValue, value: minInputValue, fractionDigits }),
      maxValue: getFormattedInputValue({ formatInputValue, value: maxInputValue }),
      hasTwoThumbs,
      showLabels,
      labelAddon,
      inputAddon,
      inputSize,
      disableInputs,
      minOutputText,
      rangeName: name,
      onInputChange: this.handleInputChange,
      onInputBlur: this.onInputBlur,
      onInputFocus: this.onInputFocus,
      onInputKeyDown: this.onInputKeyDown,
      step,
      min,
      max,
      fractionDigits,
    };

    return renderInputsPanel ? renderInputsPanel(panelProps) : <RangeInputsPanel {...panelProps} />;
  }

  render() {
    const {
      min,
      max,
      selectedMin,
      selectedMax,
      hasTwoThumbs,
      name,
      options,
      minOutputText,
      maxOutputText,
      step,
      withInput,
      disabled,
      wrapStyles,
      mainColor,
      disabledColor,
      hasColorInvertedThumb,
      rangeGroupClassName,
      withOptionsScale,
      className,
      label,
      labelsPanelPlacement,
    } = this.props;

    const lblId = name
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[()]/g, '');
    const hasOptions = !!options.length;
    const idMin = `${lblId}-range-min`;
    const idMax = `${lblId}-range-max`;

    // Support natural range look when min and max values are equal
    const hasEqualMinMax = min === max;
    const maxValue = hasEqualMinMax ? max + 1 : max;
    const disableInputs = disabled || hasEqualMinMax;

    const minRangeValue = selectedMin;
    const maxRangeValue = selectedMax + (hasEqualMinMax ? 1 : 0);

    const styleVariables = {
      '--a': minRangeValue,
      '--b': maxRangeValue,
      '--min': min,
      '--max': maxValue,
      '--main-color': disableInputs && disabledColor ? disabledColor : mainColor,
    };

    const labelsPanel = (
      <div className={classnames('d-flex justify-content-between align-items-center')}>
        {withInput && this.renderInputsPanel()}
        {!withInput && !label && (
          <Fragment>
            <span className="size-16 text-primary-darker fw-bold">{minOutputText}</span>
            {hasTwoThumbs && <span className="size-16 text-primary-darker fw-bold">{maxOutputText}</span>}
          </Fragment>
        )}
        {!withInput && label}
      </div>
    );

    return (
      <div className={classnames('range', className)}>
        {labelsPanelPlacement === LABELS_PANEL_PLACEMENTS.TOP && (
          <div className={hasOptions && withOptionsScale ? 'mb-3_5' : 'mb-1_5'}>{labelsPanel}</div>
        )}
        <div
          className={classnames(
            'wrap',
            rangeGroupClassName,
            { 'two-thumbs': hasTwoThumbs },
            { 'inverted-thumb': hasColorInvertedThumb }
          )}
          role="group"
          aria-labelledby={`multi-lbl-${lblId}`}
          style={{ ...styleVariables, ...wrapStyles }}
        >
          <div className="visually-hidden" id={`multi-lbl-${lblId}`}>
            {name}
          </div>
          <input
            id={idMin}
            type="range"
            className="range-slider-input top-slider"
            min={min}
            max={maxValue}
            value={minRangeValue}
            step={step}
            aria-valuetext={minOutputText}
            onChange={this.handleChangeMin}
            onTouchStart={this.onTouchStart}
            onTouchEnd={this.onTouchEnd}
            onMouseUp={this.onMouseUp}
            onBlur={this.onInputBlur}
            onKeyDown={this.onInputKeyDown}
            disabled={disableInputs}
            {...(hasTwoThumbs
              ? {
                  'aria-label': `Min ${name} value`,
                  // Only the slider with the "top-slider" class needs these event handlers
                  onChange: this.handleChangeForTwoThumbsSlider,
                  onMouseDown: this.onMouseDown,
                  onMouseMove: this.onPointerMove,
                  onTouchMove: this.onPointerMove,
                }
              : { 'aria-labelledby': `multi-lbl-${lblId}` })}
            ref={this.minSliderRef}
          />
          {hasTwoThumbs && (
            <input
              id={idMax}
              type="range"
              className="range-slider-input"
              min={min}
              max={maxValue}
              value={maxRangeValue}
              step={step}
              aria-valuetext={maxOutputText}
              aria-label={`Max ${name} value`}
              ref={this.maxSliderRef}
              onChange={this.handleChangeMax}
              onTouchStart={this.onTouchStart}
              onTouchEnd={this.onTouchEnd}
              onMouseUp={this.onMouseUp}
              onBlur={this.onInputBlur}
              onKeyDown={this.onInputKeyDown}
              disabled={disableInputs}
            />
          )}
        </div>
        {hasOptions && withOptionsScale && (
          <div className="range-slider-options d-flex justify-content-between align-items-end w-100">
            {options.map((opt, ind) => (
              <p
                key={opt || ind}
                className={classnames('option size-12 d-flex flex-column mb-0', {
                  'align-items-start': ind === 0,
                  'align-items-end': ind === options.length - 1,
                  'align-items-center': ind !== 0 && ind !== options.length - 1,
                })}
              >
                <span className="label mb-0_5 text-gray">{opt}</span>
                <span className="option-marker" />
              </p>
            ))}
          </div>
        )}
        {labelsPanelPlacement === LABELS_PANEL_PLACEMENTS.BOTTOM && (
          <div className={hasOptions && withOptionsScale ? 'mt-3_5' : 'mt-1_5'}>{labelsPanel}</div>
        )}
      </div>
    );
  }
}

Range.propTypes = {
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  selectedMin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hasTwoThumbs: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  minOutputText: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxOutputText: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  withInput: PropTypes.bool,
  disabled: PropTypes.bool,
  step: PropTypes.number,
  onMinChange: PropTypes.func,
  onMaxChange: PropTypes.func,
  handleUpdate: PropTypes.func,
  inputAddon: PropTypes.string,
  wrapStyles: PropTypes.objectOf(PropTypes.string),
  formatInputValue: PropTypes.bool,
  mainColor: PropTypes.string,
  disabledColor: PropTypes.string,
  hasColorInvertedThumb: PropTypes.bool,
  allowEqualValues: PropTypes.bool,
  showLabels: PropTypes.bool,
  labelAddon: PropTypes.string,
  rangeGroupClassName: PropTypes.string,
  withOptionsScale: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.node,
  renderInputsPanel: PropTypes.func,
  fractionDigits: PropTypes.number,
  labelsPanelPlacement: PropTypes.oneOf(Object.values(LABELS_PANEL_PLACEMENTS)),
};

Range.defaultProps = {
  selectedMin: null,
  selectedMax: null,
  hasTwoThumbs: false,
  name: 'range',
  options: [],
  minOutputText: undefined,
  maxOutputText: undefined,
  withInput: false,
  disabled: false,
  step: 1,
  onMinChange: noop,
  onMaxChange: noop,
  handleUpdate: noop,
  inputAddon: null,
  wrapStyles: undefined,
  formatInputValue: false,
  mainColor: '#0069bf',
  disabledColor: undefined,
  hasColorInvertedThumb: false,
  allowEqualValues: false,
  showLabels: false,
  labelAddon: '',
  rangeGroupClassName: 'mb-1_25',
  withOptionsScale: true,
  className: '',
  label: null,
  renderInputsPanel: undefined,
  fractionDigits: 0,
  labelsPanelPlacement: 'top',
};
