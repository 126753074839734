import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, debounce } from 'lodash';
import {
  buildFacetRangeFilterValue,
  buildCombinedRangeLabel,
  getFormattedUsurpLabel,
  fireUpdateTrackingPixel,
} from 'site-modules/shared/utils/inventory-utils/range-facet-utils';
import { CURRENCY, MULTIPLE_VALUE } from 'site-modules/shared/constants/inventory/facets-config';
import { LABELS_PANEL_PLACEMENTS } from 'site-modules/shared/components/range/range';
import { YEAR } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { Range } from 'site-modules/shared/components/inventory/range/range';
import { UsurpRangeInputsPanel } from 'site-modules/shared/components/inventory/usurp-range-inputs-panel/usurp-range-inputs-panel';
import { UsurpHistogram } from 'site-modules/shared/components/inventory/usurp-histogram/usurp-histogram';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { numberWithCommas } from 'site-modules/shared/utils/string';
import './usurp-range-filter.scss';

export class UsurpRangeFilter extends Component {
  static propTypes = {
    facet: PropTypes.shape({}).isRequired,
    onUpdate: PropTypes.func.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    preselectedLeft: PropTypes.number.isRequired,
    preselectedRight: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    searchResultsFound: PropTypes.number,
  };

  static defaultProps = {
    disabled: false,
    searchResultsFound: undefined,
  };

  state = {
    selectedLeft: this.props.preselectedLeft,
    selectedRight: this.props.preselectedRight,
  };

  getLabel = value => {
    const { facet, max } = this.props;
    const { type, config, upperBound } = facet;
    const { labelType, labelPostfix } = config;
    const isMaxValue = value === max;

    return getFormattedUsurpLabel({ facetType: type, labelType, name: value, isMaxValue, upperBound, labelPostfix });
  };

  handleLeftChange = (newLeftValue, callUpdateAfter) => {
    if (isFinite(+newLeftValue)) {
      this.setState({ selectedLeft: newLeftValue }, () => {
        if (callUpdateAfter) {
          this.handleRangeUpdate(true);
        }
      });
    }
  };

  handleRightChange = (newRightValue, callUpdateAfter) => {
    if (isFinite(newRightValue)) {
      this.setState({ selectedRight: newRightValue }, () => {
        if (callUpdateAfter) {
          this.handleRangeUpdate(true);
        }
      });
    }
  };

  handleRangeUpdate = isDirectInputChange => {
    const { selectedLeft, selectedRight } = this.state;
    const { onUpdate, facet, preselectedLeft, preselectedRight, max: rangeMax } = this.props;
    const { type, config, upperBound } = facet;

    const isLeftLessThanRight = selectedLeft <= selectedRight;

    const selectedMinVal = isLeftLessThanRight ? selectedLeft : selectedRight;
    const max = isLeftLessThanRight ? selectedRight : selectedLeft;

    const selectedMaxVal = !upperBound && max === rangeMax ? MULTIPLE_VALUE : max;

    const hasMaxValChanged = preselectedRight !== selectedRight;
    const hasMinValChanged = preselectedLeft !== selectedLeft;

    if (hasMaxValChanged || hasMinValChanged) {
      const preparedFacet = {
        type,
        name: buildCombinedRangeLabel(selectedMinVal, selectedMaxVal, config),
        value: buildFacetRangeFilterValue(selectedMinVal, selectedMaxVal),
      };

      const creativeId = get(config, 'creativeId', '');

      fireUpdateTrackingPixel({
        type,
        value: preparedFacet.value,
        name: preparedFacet.name,
        creativeId,
        isDirectInputChange,
      });

      onUpdate(preparedFacet, true);
    }
  };

  debouncedUpdate = debounce(this.handleRangeUpdate, 300);

  renderInputsPanel = panelProps => {
    const {
      facet: {
        config: { labelType },
      },
    } = this.props;
    return (
      <UsurpRangeInputsPanel
        className={classnames('usurp-range-filter-inputs-panel', {
          'usurp-range-filter-inputs-panel-price': labelType === CURRENCY,
        })}
        {...panelProps}
      />
    );
  };

  render() {
    const { selectedLeft, selectedRight } = this.state;
    const { facet, min, max, disabled, searchResultsFound } = this.props;
    const { config, type, averageValue } = facet;
    const { title, label, labelType, step, allowEqualValues, withHistogram } = config;
    const name = title || label;
    const inputAddon = labelType === CURRENCY ? '$' : null;

    const isLeftLessThanRight = selectedLeft <= selectedRight;

    const commonRangeProps = {
      min,
      max,
      selectedMin: selectedLeft,
      selectedMax: selectedRight,
      hasTwoThumbs: true,
      name,
      minOutputText: this.getLabel(isLeftLessThanRight ? selectedLeft : selectedRight),
      maxOutputText: this.getLabel(isLeftLessThanRight ? selectedRight : selectedLeft),
      onMinChange: this.handleLeftChange,
      onMaxChange: this.handleRightChange,
      handleUpdate: this.debouncedUpdate,
      step,
      allowEqualValues,
      withInput: true,
      formatInputValue: type !== YEAR,
      disabled,
      showLabels: true,
      labelAddon: type === YEAR ? 'Year ' : '',
      rangeGroupClassName: '',
      inputAddon,
      renderInputsPanel: this.renderInputsPanel,
    };

    return (
      <Fragment>
        {withHistogram && !!averageValue && (
          <>
            <div style={{ marginBottom: '8px' }}>
              <UsurpHistogram facet={facet} min={selectedLeft} max={selectedRight} />
            </div>
            <div
              className="d-flex justify-content-center flex-wrap mb-1 rounded-8 bg-cool-gray-80 medium"
              style={{ padding: '12px 12px' }}
            >
              <div className="text-nowrap" style={{ paddingRight: '4px' }}>
                {numberWithCommas(searchResultsFound || 0)} cars
              </div>
              |
              <div className="text-blue-30 text-nowrap fw-medium" style={{ paddingLeft: '4px' }}>
                {formatPriceString(averageValue)} avg. price
              </div>
            </div>
          </>
        )}
        <Range {...commonRangeProps} labelsPanelPlacement={LABELS_PANEL_PLACEMENTS.TOP} />
      </Fragment>
    );
  }
}
