import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { constant, get } from 'lodash';
import classnames from 'classnames';
import { InventoryEntities } from 'client/data/models/inventory';
import { FacetsConfigMapShape } from 'site-modules/shared/constants/inventory/filters-constants';
import {
  getFacetTrackingParams,
  getSelectedFacetTrackingParams,
  getSelectedFacetTrackingParamsSemanticSearch,
} from 'site-modules/shared/utils/inventory/tracking';

import { buildFilterPixelValue } from 'site-modules/shared/utils/inventory-utils/build-filter-pixel-value';

import './suggested-facet.scss';

export const SuggestedFacet = memo(({ filter, onClick, searchId, shouldWrap, facetsConfigMap, withoutRemoval }) => {
  const handleClick = useCallback(() => {
    const { type, selected } = filter;
    onClick(type, filter, !selected);
  }, [filter, onClick]);

  const { type, value, name, selected, niceName } = filter;
  const { facetsConfig, facetsGroupsConfig } = facetsConfigMap;

  const getDisplayName = get(facetsConfig, [type, 'getDisplayName'], constant(name));

  const dataTrackingValue = buildFilterPixelValue(type, value, name);
  const trackingData = selected
    ? getSelectedFacetTrackingParams(dataTrackingValue)
    : getFacetTrackingParams(dataTrackingValue);
  const facetGroup = get(facetsConfig, [type, 'group']);
  const creativeId = get(facetsConfig, [type, 'creativeId']) || get(facetsGroupsConfig, [facetGroup, 'creativeId']);

  return (
    <button
      type="button"
      className={classnames('suggested-facet d-flex align-items-center size-14 px-0_75', {
        selected,
        'bg-cool-gray-100 text-cool-gray-30': !selected,
        'fw-medium bg-blue-90 overlay-white-60 text-blue-30': selected,
        'text-nowrap': shouldWrap,
      })}
      onClick={handleClick}
      disabled={withoutRemoval}
      aria-label={`${selected && !withoutRemoval ? 'Clear' : 'Apply'} ${niceName || getDisplayName(filter)} filter`}
      aria-pressed={!!selected}
      {...(searchId
        ? getSelectedFacetTrackingParamsSemanticSearch({ value: dataTrackingValue, searchId })
        : trackingData)}
      data-tracking-parent={creativeId}
    >
      <span className="suggested-facet-name text-start">{niceName || getDisplayName(filter)}</span>
      {selected && !withoutRemoval && (
        <span
          className="selected-icon rounded-circle"
          style={{
            width: '20px',
            height: '20px',
            display: 'inline-block',
            marginLeft: '0.25rem',
            marginRight: '-0.25rem',
          }}
        >
          <span
            className="icon-cross2 size-12"
            style={{ width: '20px', height: '20px', display: 'inline-block' }}
            aria-hidden
          />
        </span>
      )}
    </button>
  );
});

SuggestedFacet.propTypes = {
  filter: InventoryEntities.FacetValue.isRequired,
  facetsConfigMap: FacetsConfigMapShape.isRequired,
  onClick: PropTypes.func.isRequired,
  searchId: PropTypes.string,
  shouldWrap: PropTypes.bool,
  withoutRemoval: PropTypes.bool,
};

SuggestedFacet.defaultProps = {
  searchId: undefined,
  withoutRemoval: undefined,
  shouldWrap: true,
};

SuggestedFacet.displayName = 'SuggestedFacet';
