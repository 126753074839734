import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { EDMUNDS_LOGO_NO_TEXT } from 'client/images/inline-svgs/edmunds-logo';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';

export function EdmundsTested({ className, tooltip }) {
  const tooltipId = tooltip?.id || '';
  const iconClassName = tooltip?.iconClassName || '';
  const tooltipText = tooltip?.text || '';
  const hasTooltip = !!tooltipText;

  return (
    <>
      <span
        className={classnames(
          'bg-cool-gray-80 fw-medium text-cool-gray-30 text-nowrap rounded-6 size-12 px-0_5 py-0_25 d-inline-block',
          className
        )}
        style={{ letterSpacing: '1px' }}
      >
        {EDMUNDS_LOGO_NO_TEXT({ fill: '#2070E8', width: '18px', classes: 'mr-0_25' })}
        edmunds
        <span className="ml-0_25 fw-bold" style={{ letterSpacing: '3px' }}>
          TESTED
        </span>
      </span>
      {hasTooltip && (
        <TooltipItem
          id={tooltipId}
          className="top-0 size-10 filters-group-wrapper-title pos-r"
          iconClassName={iconClassName}
          screenReaderTooltipLabel={tooltip?.title}
          item={{
            text: tooltipText,
            placement: 'auto',
          }}
        />
      )}
    </>
  );
}

EdmundsTested.propTypes = {
  className: PropTypes.string,
  tooltip: PropTypes.shape({ id: PropTypes.string, text: PropTypes.string, iconClassName: PropTypes.string }),
};
EdmundsTested.defaultProps = { className: '', tooltip: {} };
