import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Badge } from 'site-modules/shared/components/inventory/badge/badge';
import { Button } from 'site-modules/shared/components/inventory/button/button';

import './filters-button.scss';

const MAX_SHOWN_ACTIVE_FILTERS_COUNT = 9;

export function FilterButton({ onFiltersBtnClick, activeFiltersCount, classes, trackingId }) {
  return (
    <Button
      className={classnames(
        'filters-btn size-14 d-flex align-items-center mr-0_5 bg-cool-gray-100 text-cool-gray-30',
        classes
      )}
      color="outline"
      onClick={onFiltersBtnClick}
      data-tracking-id={trackingId}
    >
      <span className="icon-filter icon size-14 mr-0_25 text-blue-30" aria-hidden />
      Filters
      {!!activeFiltersCount && (
        <Badge className="ml-0_25">
          {activeFiltersCount > MAX_SHOWN_ACTIVE_FILTERS_COUNT
            ? `${MAX_SHOWN_ACTIVE_FILTERS_COUNT}+`
            : activeFiltersCount}
          <span className="visually-hidden">Active</span>
        </Badge>
      )}
    </Button>
  );
}

FilterButton.propTypes = {
  onFiltersBtnClick: PropTypes.func.isRequired,
  activeFiltersCount: PropTypes.number,
  classes: PropTypes.string,
  trackingId: PropTypes.string,
};

FilterButton.defaultProps = {
  activeFiltersCount: 0,
  classes: 'd-lg-none',
  trackingId: null,
};
